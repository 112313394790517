import { Form as RemixForm, useActionData } from "@remix-run/react";
import { ActionFunction } from "@remix-run/node";
import { loginUser } from "~/utils/auth.server";
import { Card, Text, Button, Flex, Heading, TextField } from "@radix-ui/themes";
import * as Form from "@radix-ui/react-form";

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData();
  const email = formData.get("email") as string;
  const password = formData.get("password") as string;

  if (!email || !password) {
    return { error: "Invalid email or password", status: 400 };
  }

  try {
    return await loginUser(email, password);
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : "Login failed";
    return Response.json({ error: errorMessage }, { status: 401 });
  }
};

export default function Login() {
  const actionData = useActionData();

  return (
    <Flex align="center" justify="center" className="h-screen">
      <Card variant="surface" className="max-w-sm w-full shadow-lg p-6">
        <Heading size="6" weight="bold" mb="4">
          Login
        </Heading>
        {actionData?.error && (
          <Text color="ruby" size="1" mb="4">
            {actionData.error}
          </Text>
        )}

        <Form.Root asChild>
          <RemixForm method="post" action="/login">
            <Form.Field name="email" className="mb-4">
              <Form.Label asChild>
                <Text as="label" size="1">
                  Email
                </Text>
              </Form.Label>
              <Form.Control asChild>
                <TextField.Root
                  placeholder="Enter your email"
                  type="email"
                  required
                />
              </Form.Control>
              <Form.Message asChild match="valueMissing">
                <Text size="1" color="ruby">
                  Email is required
                </Text>
              </Form.Message>
            </Form.Field>
            <Form.Field name="password" className="mb-4">
              <Form.Label asChild>
                <Text as="label" size="1">
                  Password
                </Text>
              </Form.Label>
              <Form.Control asChild>
                <TextField.Root
                  placeholder="Enter your password"
                  type="password"
                  required
                />
              </Form.Control>
              <Form.Message match="valueMissing">
                <Text size="1" color="ruby">
                  Password is required
                </Text>
              </Form.Message>
            </Form.Field>
            <Form.Submit asChild>
              <Button className="w-full">Login</Button>
            </Form.Submit>
          </RemixForm>
        </Form.Root>
      </Card>
    </Flex>
  );
}
